<template>
	<v-navigation-drawer v-if="$vuetify.breakpoint.mdAndUp" app class="text-center" color="#181818" permanent>
		<v-row align="center" class="flex-column fill-height py-5 mx-0" justify="center">
			<h2 class="display-3 font-weight-black primary--text mb-5" v-text="initials" />
			<div class="display-1 mb-" v-text="schema.basics.name" />
			<span class="title font-weight-light mb-10" v-text="schema.basics.label" />
			<div v-for="(item, i) in items" :key="i">
				<v-btn class="text-capitalize subheading font-weight-light mb-3" text @click="$vuetify.goTo(item.to)">
					<span v-text="item.text" />
				</v-btn>
			</div>
			<div class="mt-auto">
				<social-contacts />
			</div>
		</v-row>
	</v-navigation-drawer>
</template>

<script>
	// Utilities
	import { mapState } from "vuex"

	export default {
		name: "Drawer",

		components: {
			SocialContacts: () => import("@/components/SocialContacts"),
		},

		data: (vm) => ({
			items: [
				{
					text: "Qui suis-je ?",
					to: "#who-i-am",
				},
				{
					text: "Mes offres",
					to: "#what-i-can-do-for-you",
				},
				{
					text: "Experiences",
					to: "#where-ive-been",
				},
				{
					text: "Compétences & Formation",
					to: "#what-i-know",
				},
				{
					text: "Mon travail",
					to: "#what-ive-done",
				},
				{
					text: "Me contacter",
					to: "#lets-talk",
				},
			],
		}),

		computed: {
			...mapState("app", ["initials", "schema"]),
		},
	}
</script>
